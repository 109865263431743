import {
    BandwidthCalculatorConfig,
    BandwidthCalculatorView,
    PageState,
} from '@home-mgmt-shared/bandwidth-calculator-page';
import { BandwidthHomeIntro } from '@home-mgmt-shared/common-ui';
import { bandwidthCalculatorFlow } from '@home-mgmt-shared/flows';
import React, { useCallback, useState } from 'react';
import { QuestionAnswerIcons } from '../assets/question-answer-icons';
import { BasePage } from './BasePage';

const config: BandwidthCalculatorConfig = {
    flow: {
        imageMap: QuestionAnswerIcons,
    },
    webscan: {
        IntroComponent: ({ startSpeedTest }) => <BandwidthHomeIntro startSpeedTest={startSpeedTest} />,
        scanningPage: {
            showHeader: true,
            headerMsg: 'Make sure you’re connected to your home Wi-Fi',
        },
    },
    progressBar: true,
};

export const BandwidthCalculatorPage = () => {
    const [hideFooter, setHideFooter] = useState(true);

    const onPageChange = useCallback((page: PageState) => {
        if (page === PageState.RESULTS || page === PageState.RECOMMENDATIONS) {
            setHideFooter(false);

            return;
        }

        setHideFooter(true);
    }, []);

    return (
        <BasePage hideFooter={hideFooter}>
            <BandwidthCalculatorView flow={bandwidthCalculatorFlow} onPageChange={onPageChange} config={config} />
        </BasePage>
    );
};
