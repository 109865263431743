import * as React from 'react';

export const CloseX = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="close x"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5291 12.5001L5.23621 18.793L6.65042 20.2072L12.9433 13.9143L19.2362 20.2072L20.6504 18.793L14.3575 12.5001L20.6504 6.20718L19.2362 4.79297L12.9433 11.0859L6.65042 4.79297L5.23621 6.20718L11.5291 12.5001Z"
            fill="black"
        />
    </svg>
);
