import styled from '@emotion/styled';
import { BinaryFeedback } from '@home-mgmt-shared/binary-feedback';
import { ClickableButton, IStreamingQualityGuideline } from '@home-mgmt-shared/common-ui';
import { OverviewConfig, OverviewPageState, OverviewView } from '@home-mgmt-shared/network-overview-page';
import React, { useCallback, useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { clickEvent } from '../analytics/analytics';
import { DEFAULT_STREAMING_GUIDELINES } from '../utils';
import { BasePage } from './BasePage';

interface BackButtonConfig {
    enabledPages: OverviewPageState[];
}

const backButtonConfig: BackButtonConfig = {
    enabledPages: [],
};

const BinaryFeedbackWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const ButtonWrapper = styled.div`
    height: 100%;
    width: 5.75rem;
    max-width: 4.75rem;
    max-height: 2rem;
    margin-right: 1rem;
`;

const config: OverviewConfig = {
    BinaryFeedback: (
        <BinaryFeedbackWrapper>
            <BinaryFeedback
                greetingText="Was this speed chart helpful?"
                farewellText="Thanks for your feedback!"
                positiveFeedbackIcon={
                    <ButtonWrapper>
                        <ClickableButton label="Yes" />
                    </ButtonWrapper>
                }
                negativeFeedbackIcon={
                    <ButtonWrapper>
                        <ClickableButton label="No" />
                    </ButtonWrapper>
                }
                analyticsCb={clickEvent}
            />
        </BinaryFeedbackWrapper>
    ),
};

export const OverviewPage = () => {
    const [back, setBack] = useState<boolean>(false);
    const [showBack, setShowBack] = useState<boolean>(false);

    const streamingGuidelines: IStreamingQualityGuideline[] = useTweekValue(
        'network_scan/capabilities/streaming_capabilities',
        DEFAULT_STREAMING_GUIDELINES,
    );

    const onPageChange = useCallback((page: OverviewPageState) => {
        if (backButtonConfig.enabledPages.includes(page)) {
            setShowBack(true);
        } else {
            setShowBack(false);
        }
    }, []);
    const onPreviousPage = useCallback(() => {
        setBack(!back);
    }, [back]);

    return (
        <BasePage backButtonCallback={showBack ? onPreviousPage : undefined}>
            <OverviewView
                onPageChange={onPageChange}
                backWasClicked={back}
                overviewConfig={config}
                streamingGuidelines={streamingGuidelines}
            />
        </BasePage>
    );
};
