import * as React from 'react';

export const Streaming4k = () => (
    <svg width={63} height={45} viewBox="0 0 63 48" fill="none">
        <path
            d="M7.219 12.18a2.982 2.982 0 012.89-2.76c11.875-.335 18.926-.223 30.374.022a2.986 2.986 0 012.916 2.732c.626 7.562.768 12.733.2 20.327a2.955 2.955 0 01-2.733 2.721c-11.735.62-19.318.585-31.147-.001a2.955 2.955 0 01-2.74-2.75c-.487-7.434-.326-12.596.24-20.291z"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.987 33.195c.097 1.979 1.269 3.552 2.74 3.696 11.838 1.836 19.41 1.868 31.14.002 1.458-.145 2.622-1.698 2.731-3.66"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.945 32.65c0 2.41 1.31 4.453 2.781 4.611 11.839 2.02 19.412 2.055 31.14.003 1.459-.16 2.629-2.456 2.738-4.614"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29.118 22.117a.487.487 0 010 .844c-1.801 1.258-2.861 1.833-4.788 2.764a.487.487 0 01-.73-.422c-.153-2.172-.087-4.638-.087-5.454 0-.511.39-.657.715-.47 2.003.979 3.066 1.586 4.89 2.738z"
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.714 22.182a.414.414 0 010 .716c-1.53 1.07-2.431 1.558-4.068 2.349a.414.414 0 01-.62-.358c-.13-1.846-.074-3.941-.074-4.634 0-.434.331-.558.607-.4 1.702.832 2.605 1.348 4.155 2.327z"
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M26.997 21.673a1 1 0 010 1.732l-1.222.705a1 1 0 01-1.5-.866v-1.41a1 1 0 011.5-.866l1.222.705z"
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M26.119 21.784a.872.872 0 010 1.51v0a.871.871 0 01-1.308-.755v0c0-.67.727-1.09 1.308-.755v0zM26.711 22.539h-1.759"
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M58.754 14.838c0 4.532-1.675 9.69-6.911 9.69-4.416.606-6.734.886-6.734-3.899 0-2.126-6.734 0-6.734-3.544 0-3.898 3.917-7.357 5.848-8.86 1.679-1.306 3.413-2.658 5.848-2.658 5.236 0 8.683 4.738 8.683 9.27z"
            fill="#fff"
        />
        <path
            d="M48.348 21.748V8.878l-6.517 7.528h8.217M52.962 8.878v6.556m0 6.557v-6.557m7.042-6.556l-7.042 6.556m0 0l8.538 4.487"
            stroke="#000"
            strokeWidth={1.943}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
