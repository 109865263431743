import { TweekProvider } from '@home-mgmt-shared/tweek-helpers';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { initializeLogger, initializeWixiSdk } from './utils';

initializeWixiSdk();
initializeLogger();

const AppComponent = () => (
    <TweekProvider>
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    </TweekProvider>
);

export const App = AppComponent;
