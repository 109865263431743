import { Theme } from '@emotion/react';

export const bandwidthTheme: Theme = {
    font: {
        type: 'ApercuPro',
        bold: 'ApercuPro',
        accentColor: '#000000',
    },
    accentColors: {
        primary: '#8223D2',
        secondary: '#000000',
    },
    button: {
        primaryColor: '#8223D2',
        selectedColor: '#8223D2',
        disabledColor: '#E6E6EB',
        disabledTextColor: '#A5AAAF',
        defaultTextColor: '#000000',
    },
    messageAlert: {
        backgroundColor: '#F0F0F5',
        font: {
            type: 'ApercuPro',
            bold: 'ApercuPro',
            accentColor: '#000000',
        },
    },
    speedResults: {
        slowColor: '#FF5070',
        okayColor: '#EDB800',
        decentColor: '#00E7A1',
        goodColor: '#00B574',
    },
    special: {
        primaryColor: '#000000',
        secondaryColor: '#37E7A7',
    },
    spinner: {
        primaryColor: '',
    },
    components: {
        questionAnswer: {
            answerAccent: '#000000',
        },
        questionAnswerGrid: {
            answerAccent: '#000000',
        },
        linkColor: '#000000',
    },
};
