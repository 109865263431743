import React from 'react';

export const TooltipTriangle = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="tooltip triangle"
    >
        <path d="M8.96245 0.991699H0.398926L4.68069 5.9917L8.96245 0.991699Z" fill="#E6E6EB" />
    </svg>
);
