import React, { useCallback, useState } from 'react';
import { PageState, GenericScanView, GenericScanConfig } from '@home-mgmt-shared/generic-scan-page';
import { SpeedTestHomeIntro } from '@home-mgmt-shared/common-ui';
import { BasePage } from './BasePage';

const genericScanConfig: GenericScanConfig = {
    results: {
        checkSpeedAgainButtonDesktopColumn: 'left',
        expertCTADesktopColumn: 'left',
        resultsHeader: 'Your results are in',
        resultsSubHeader: 'Your speed',
    },
    webscan: {
        IntroComponent: ({ startSpeedTest }) =>
            SpeedTestHomeIntro({
                startSpeedTest,
            }),
        scanningPage: {
            showHeader: true,
        },
    },
};
export const ScanPage = () => {
    const [hideFooter, setHideFooter] = useState(true);

    const onPageChange = useCallback((page) => {
        if (page === PageState.RESULTS) {
            setHideFooter(false);
        } else {
            setHideFooter(true);
        }
    }, []);

    return (
        <BasePage hideFooter={hideFooter}>
            <GenericScanView config={genericScanConfig} onPageChange={onPageChange} />
        </BasePage>
    );
};
