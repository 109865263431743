import { FlowData, LOCAL_STORAGE_PROPERTY } from '@home-mgmt-shared/save-results';
import { SpeedPoint, SpeedType } from '@home-mgmt-shared/speed-chart';
import { ScanResponse } from '@soluto-private/wixi-web-sdk';

const getSpeedPointFromScanResponse = (
    timestamp: string,
    internetHealth: Record<string, string>,
    speedType: SpeedType,
): SpeedPoint | undefined => {
    let timeAdjusted = timestamp;
    if (timestamp.indexOf(' ') === 10) timeAdjusted = timestamp.replace(' ', 'T');

    const date = new Date(timeAdjusted);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = `${date.getFullYear()}`.substring(2);
    const formattedDate = `${month}/${day}/${year}`;

    const speed = Number(
        speedType === SpeedType.UPLOAD ? internetHealth.uploadThroughput : internetHealth.downloadThroughput,
    );
    if (Number.isNaN(speed)) {
        return undefined;
    }
    const speedPoint: SpeedPoint = { date: formattedDate, speed: Number(speed.toFixed(0)) };

    return speedPoint;
};

export const getSpeedPointsFromScanData = (
    scanData: ScanResponse[] | undefined,
    speedType: SpeedType,
): SpeedPoint[] | undefined => {
    const speedPoints: SpeedPoint[] = [];

    scanData?.forEach((d: ScanResponse) => {
        if (d.timestamp && d.internetHealth) {
            const speedPoint = getSpeedPointFromScanResponse(d.timestamp, d.internetHealth, speedType);

            if (speedPoint) speedPoints.push(speedPoint);
        }
    });

    if (speedPoints.length > 0) return speedPoints;

    return undefined;
};

// TODO: Tweekify
const IDEAL_SPEED_THRESHOLD = 0.8;

export const isSpeedIdeal = (speed: number, idealSpeed: number, idealSpeedThreshold?: number): boolean =>
    speed > idealSpeed * (idealSpeedThreshold ?? IDEAL_SPEED_THRESHOLD);

export const getNewScansFromFlowData = (results: ScanResponse[], flowDataString: string) => {
    const flowDataArray: FlowData[] = JSON.parse(flowDataString) as FlowData[];
    const newScans: ScanResponse[] = results;
    const newFlowDataArray: FlowData[] = [];

    flowDataArray.forEach((flowData: FlowData) => {
        if (results.find((result) => result.goId === flowData.scanId) === undefined) {
            const newScan: ScanResponse = {
                internetHealth: {
                    downloadThroughput: flowData.downloadSpeed.toFixed(2),
                    uploadThroughput: flowData.uploadSpeed.toFixed(2),
                },
                timestamp: flowData.timestamp,
            };
            newScans.push(newScan);
            newFlowDataArray.push(flowData);
        }
    });

    if (newFlowDataArray.length > 0) {
        localStorage.setItem(LOCAL_STORAGE_PROPERTY, JSON.stringify(newFlowDataArray));
    } else {
        localStorage.removeItem(LOCAL_STORAGE_PROPERTY);
    }

    newScans.sort((a, b) => {
        if (a.timestamp && b.timestamp) {
            if (new Date(a.timestamp) < new Date(b.timestamp)) {
                return -1;
            }
            return 1;
        }
        return 0;
    });
    return newScans;
};
