import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { AsurionFooter, AsurionHeader } from '../components';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 16px;
    background: #fff;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @supports (-webkit-touch-callout: none) {
        height: 100%;
    }
`;

const ContentContainer = styled.div`
    flex: 1;
    @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available;
    }
`;

interface BasePageProps {
    children?: ReactNode;
    hideFooter?: boolean;
    hideHeader?: boolean;
    backButtonCallback?: () => void;
}

export const BasePage = ({ children, hideFooter, hideHeader, backButtonCallback }: BasePageProps) => (
    <PageContainer>
        {!hideHeader && <AsurionHeader backButtonCallback={backButtonCallback} />}
        <ContentContainer>{children}</ContentContainer>
        {!hideFooter && <AsurionFooter />}
    </PageContainer>
);
