import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    display: inline-flex;
    max-width: 500px;
    padding: 0px 15px;
    height: 350px;
`;

export const LoadingAnimation = () => (
    <Container>
        <div className="dot-typing" />
    </Container>
);
