import React from 'react';

interface HazardProps {
    fillColor?: string;
}

export const Hazard = ({ fillColor }: HazardProps) => (
    <svg
        width="57"
        height="48"
        viewBox="0 0 57 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="hazard"
    >
        <path
            d="M30.8431 47.2721C25.7284 47.1428 20.6046 47.0505 15.4899 46.8566C12.854 46.755 10.218 46.4873 7.59136 46.238C5.63059 46.0534 3.82707 45.3609 2.2825 44.133C1.82006 43.7637 1.42235 43.1728 1.25587 42.6003C0.543708 40.2183 0.663948 37.864 1.83856 35.6389C4.0028 31.5303 6.13004 27.3941 8.40527 23.3502C9.77411 20.9035 11.3649 18.5769 12.9095 16.2225C15.1385 12.8249 17.3582 9.41802 19.6982 6.09425C20.7526 4.59856 21.9549 3.14902 23.296 1.91184C25.5435 -0.165515 29.4742 0.0930001 31.472 2.40117C33.6085 4.8663 35.7913 7.3222 37.6688 9.98122C39.8978 13.1203 41.7938 16.4903 43.884 19.7309C44.9569 21.3836 46.2148 22.907 47.2691 24.5689C49.2299 27.6434 51.0982 30.7733 52.9942 33.8847C53.6694 34.9926 54.3076 36.1282 54.9827 37.2454C57.147 40.8646 55.8429 43.9298 52.1896 45.5271C50.682 46.1826 48.971 46.6166 47.3339 46.7089C42.395 46.9859 37.4376 47.0413 32.4802 47.1797C31.9345 47.1982 31.3888 47.1797 30.8431 47.1797C30.8431 47.2074 30.8431 47.2351 30.8431 47.2721ZM33.5993 44.5392C33.5993 44.5484 33.5993 44.5577 33.5993 44.5669C34.9404 44.5669 36.2907 44.6131 37.6318 44.5577C41.8215 44.4007 46.0298 44.3545 50.201 43.9945C52.9202 43.7544 54.9735 41.4462 53.3087 38.2517C52.5503 36.8022 51.7826 35.3527 50.9132 33.977C48.6195 30.3208 46.2887 26.6924 43.9303 23.0824C41.7753 19.7863 39.6388 16.4626 37.3451 13.2588C34.9589 9.91659 32.4432 6.66668 29.8997 3.44447C28.8176 2.0688 26.8106 1.83798 25.34 2.86281C24.3226 3.57373 23.2868 4.39544 22.5746 5.38334C20.5676 8.16238 18.7363 11.0799 16.8125 13.9143C13.2332 19.1862 9.61687 24.4489 6.74046 30.1454C5.44562 32.7121 4.26177 35.3804 3.35538 38.104C2.17152 41.6863 3.77157 43.9114 7.55437 44.2161C10.1441 44.4284 12.7522 44.5115 15.3512 44.53C21.437 44.5669 27.5227 44.5392 33.5993 44.5392Z"
            fill={fillColor ?? 'black'}
        />
        <path
            d="M29.2157 21.1527C28.9844 24.0795 28.7255 27.3755 28.4665 30.6624C28.448 30.9117 28.4387 31.1794 28.3278 31.401C28.2075 31.641 28.004 31.9642 27.8006 31.9919C27.5878 32.0196 27.2179 31.7888 27.1254 31.5856C26.8017 30.8655 26.4965 30.1176 26.3207 29.3513C25.4236 25.372 24.9981 21.3373 25.2479 17.2657C25.3311 15.9824 25.6086 14.699 25.9323 13.4618C26.108 12.7971 26.4965 12.0492 27.3844 12.1416C28.2908 12.2339 28.6237 12.991 28.6885 13.7665C28.8827 16.0932 29.0214 18.4383 29.2157 21.1527Z"
            fill={fillColor ?? 'black'}
        />
        <path
            d="M27.8467 38.898C26.9219 38.9257 26.3299 38.381 26.2929 37.4762C26.2467 36.4329 26.8756 35.6112 27.7265 35.6204C28.5867 35.6297 29.4838 36.516 29.5208 37.4023C29.5485 38.2425 28.8456 38.8703 27.8467 38.898Z"
            fill={fillColor ?? 'black'}
        />
    </svg>
);
