import styled from '@emotion/styled';
import { BackButton } from '@home-mgmt-shared/common-ui';
import React from 'react';
import { AsurionLogo } from '../assets/AsurionLogo';

const HeaderContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: left;
    z-index: 200;
    align-items: flex-start;
    cursor: default;
`;

const Logo = styled.div`
    margin: 0.5rem 1rem;
`;

const HeaderContent = styled.div`
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`;

interface AsurionHeaderProps {
    backButtonCallback?: () => void;
}

export const AsurionHeader = ({ backButtonCallback }: AsurionHeaderProps) => (
    <HeaderContainer>
        <HeaderContent>
            <Logo>
                <AsurionLogo />
            </Logo>
        </HeaderContent>
        {backButtonCallback !== undefined && <BackButton backButtonCallback={backButtonCallback} />}
    </HeaderContainer>
);
