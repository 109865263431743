import styled from '@emotion/styled';
import { usePageAnalytics } from '@home-mgmt-shared/analytics';
import { CardView, ClickableButton } from '@home-mgmt-shared/common-ui';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { AnalyticEvents, ERROR_SUBHEADER, NETWORK_CHECKUP_HEADER, RoutePaths, TRY_AGAIN } from '../utils';

const Card = styled(CardView)`
    margin-top: 15px;
    margin-bottom: 0px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ErrorPageTitle = styled.h1`
    font-size: 24px;
    font-family: 'ApercuPro';
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0px;
`;

const ErrorPageSubHeading = styled.p`
    font-family: 'ApercuPro';
    font-size: 20px;
    text-align: center;
`;
const PageContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    display: inline-flex;
    max-width: 500px;
    padding: 0px 15px;
`;

export const ErrorPage = () => {
    usePageAnalytics('Error Page');
    const history = useHistory();
    const routeToScanResultsPage = useCallback(() => {
        history.push(RoutePaths.SCAN_RESULTS);
    }, [history]);

    return (
        <PageContainer>
            <Helmet>
                <title>Error</title>
            </Helmet>
            <Card>
                <ErrorPageTitle>{NETWORK_CHECKUP_HEADER}</ErrorPageTitle>
                <ErrorPageSubHeading>{ERROR_SUBHEADER}</ErrorPageSubHeading>
                <ClickableButton
                    label={TRY_AGAIN}
                    onClick={routeToScanResultsPage}
                    analyticEventName={AnalyticEvents.TRY_AGAIN_BUTTON}
                />
            </Card>
        </PageContainer>
    );
};
