import * as React from 'react';

export const Gaming = () => (
    <svg width={49} height={45} viewBox="0 0 49 49" fill="none">
        <path
            d="M4.68 37.934c-.037-.846 0-1.655 0-2.494v-1.802c-.043-3.45-.864-8.376 3.135-8.48 3.179-.106 7.234-.08 11.456.073 4.413.192 12.412.186 16.868.34 3.765.117 7.209-.42 7.29 4 .024 1.839-.05 3.956-.205 5.95-.19 1.999-.13 4.215-.438 6.214-.074.655.037 1.42-.383 2-1.073 1.457-5.153 1.235-6.653 1.389-6.69.358-10.24-.019-16.232-.056-.963 0-4.376-.105-5.34-.105-.808 0-4.104-.203-5.64-.203-2.26-.204-3.722-.519-3.796-2.975 0-.006-.019-2.778-.062-3.851z"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.08 37.557c0-.704.117-1.222.074-1.889 0-.55-.043-1.055-.043-1.605 0-1.215.08-2.388.167-3.567.13-1.21.456-1.84 1.333-2.364.876-.315 1.864-.42 2.734.315.654.734.698 3.592.654 4.61 0 2.272.111 2.303 0 3.981-.166.939-.327 3.16-.987 3.981-.494.63-2.08.945-3.21.223-.944-.618-.728-2.198-.722-3.685z"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.351 36.927c-.067-.123-2.746-.191-3.153-.197-.494-.012-1.753.105-2.21.092-1.191-.012-2.802-.197-3.068-1.678.044-2.29 1.531-2.303 3.352-2.37 2.561-.038 3.962.049 5.845.172.981.068 1.203.105 2.518.21.765.21 1.426 1.05 1.426 2.093-.327 1.573-1.753 1.573-4.376 1.678"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.64 30.805c.08-.827 1.277-1.654 2.055-1.198 1.253.747.08 2.691-1.198 2.24-.006 0-.716-.172-.858-1.042zM35.54 35.409c-.024-.24.025-.481.136-.691.32-.611 1.092-.803 1.734-.611.747.216.803 1.358.29 1.864-.425.426-1.037.32-1.518.105 0 0-.611-.531-.642-.667z"
            fill="#000"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.147 24.497c-.074 0 .037-.537.037-.574.037-.382.154-1.382.463-1.654.463-.42 1.728-.154 2.302-.154 2.24.024 3.34-.155 3.265 1.265 0 .272 0 .5-.037.765M24.832 21.732c-.037.117-.037-.229-.037-.346 0-.228 0-.463.037-.691.117-.728.382-1.457.691-2.11.963-2.112 2.537-3.877 4.148-5.494 2.802-2.765 6.412-5.222 10.443-5.567 2.839-.229 5.758 1.191 6.258 4.184.691 4.074-4.16 5.956-7.153 6.839-.729.228-3.697.716-5.34.716-6.362.309-11.825-1.278-17.238-4.617-4.413-2.728-8.11-6.77-8.517-11.43M22.53 39.433c.382-.037 2.536-.19 2.765-.117M28.455 39.618c-.037 0 .271-.037.308-.037.229 0 2.111-.333 2.648-.104M42.916 40.958s-.105 1.642-.716 2.358c-.309.345-1.235 1.024-2.259 1.13-1.846.308-2.568.308-4.105.308-5.542.308-7.122.16-11.226.395"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
