import {
    retrieveItem,
    retrieveItemFromLocalStorage,
    storeItem,
    storeItemInLocalStorage,
} from '@home-mgmt-shared/common-services';
import { UserBrowser, USER_BROWSER_LOCAL_STORAGE_KEY_NAME } from '@home-mgmt-shared/common-ui';
import { initFullstory, setIdentityForFullStory } from '@home-mgmt-shared/fullstory';
import { updateTweekContext } from '@home-mgmt-shared/tweek-helpers';
import { analytics } from '@soluto-private/ns-analytics';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
import { BandwidthCalculatorPage, ErrorPage, MultiScanPage, OverviewPage, ScanPage } from '../pages';
import { withThemeProviderWrapper } from '../providers';
import { bandwidthTheme, overviewTheme, webScanTheme } from '../themes';
import {
    APPLICATION_ID,
    APP_NAME,
    AUTH_TOKEN,
    CID,
    config,
    FLOW_ID,
    isProduction,
    IS_TEST,
    PARTNER,
    PROGRAM,
    SUB_ID,
    USER_BROWSER_ID,
    USER_ID,
} from '../utils';

const useQueryParamRemover = (): boolean => {
    const [done, setDone] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const authToken = params.get(AUTH_TOKEN);

        if (authToken) {
            storeItem(AUTH_TOKEN, authToken);
            history.replace(location.pathname);
            setDone(true);
        }

        setDone(true);
    }, [history, location.pathname, location.search]);

    return done;
};

const initializeParamsAndAnalytics = (queryParams: string): void => {
    // applicationId is the clientId for fetching sessions + homegraph data for app scan
    // userId is the supplied userId (asurionId for now) for the web scan
    const params = new URLSearchParams(queryParams);
    const applicationId = params.get(APPLICATION_ID) ?? retrieveItem(APPLICATION_ID);
    const flowId = params.get(FLOW_ID) ?? retrieveItem(FLOW_ID);
    const cid = params.get(CID) ?? retrieveItem(CID);
    const program = params.get(PROGRAM) ?? retrieveItem(PROGRAM);
    const userId = params.get(USER_ID) ?? retrieveItem(USER_ID);
    const subscriberId = params.get(SUB_ID) ?? retrieveItem(SUB_ID);
    const isTest = params.get(IS_TEST);
    const userBrowserId =
        params.get(USER_BROWSER_ID) ??
        retrieveItemFromLocalStorage(USER_BROWSER_LOCAL_STORAGE_KEY_NAME) ??
        UserBrowser.Id;

    storeItem(APPLICATION_ID, applicationId);
    storeItem(FLOW_ID, flowId);
    storeItem(USER_ID, userId);
    storeItem(SUB_ID, subscriberId);
    storeItem(CID, cid);
    storeItem(PROGRAM, program);
    storeItemInLocalStorage(USER_BROWSER_LOCAL_STORAGE_KEY_NAME, userBrowserId);

    analytics.setCustomParamValidator(() => true);
    updateTweekContext({ partner: PARTNER, '@@id': userBrowserId, hostname: window.location.hostname, program });

    analytics.updateProperties({
        browserId: userBrowserId,
        partner: PARTNER,
        appName: APP_NAME,
        userId: userId || applicationId,
        flow: flowId && flowId,
        subscriberId,
        isTest: !isProduction() || !!isTest,
        env: config.env,
        cid,
        program,
    });

    initFullstory(!isProduction() || !!isTest);
    if (!isTest && isProduction()) {
        setIdentityForFullStory(userBrowserId, {
            partner_str: PARTNER,
            cid_str: retrieveItem(CID) ?? '',
        });
    }
};

const Routes = () => {
    const location = useLocation();
    useEffect(() => {
        initializeParamsAndAnalytics(location.search);
    }, [location.search]);

    const canRoute = useQueryParamRemover();

    // do this while we are waiting to strip the query params to prevent remount routes
    if (!canRoute) {
        return null;
    }

    return (
        <Switch>
            <Route path="/error" component={ErrorPage} />
            <Route path="/bandwidth" component={withThemeProviderWrapper(BandwidthCalculatorPage, bandwidthTheme)} />
            <Route path="/scan" component={withThemeProviderWrapper(ScanPage, webScanTheme)} />
            <Route path="/signal" component={withThemeProviderWrapper(MultiScanPage, webScanTheme)} />
            <Route path="/overview" component={withThemeProviderWrapper(OverviewPage, overviewTheme)} />
            <Route path="/" component={withThemeProviderWrapper(ScanPage, webScanTheme)} />
        </Switch>
    );
};

export default withRouter(Routes);
