import * as React from 'react';

export const VideoCalls = () => (
    <svg width={51} height={45} viewBox="0 0 51 36" fill="none">
        <path
            d="M19.085 20.06c-.243-.244-.568-.487-.811-.812-1.055-1.217-1.704-3.002-1.298-4.706a4.51 4.51 0 01.73-1.379c1.947-2.84 6.41-2.758 8.113.406.974 1.785.568 4.38-.892 5.841-.568.568-1.46.893-2.191 1.136-1.136.406-2.353.325-3.489-.406l-.162-.08z"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.03 28.821c.08-.73.08-.973.162-1.46.08-.649.324-1.298.567-1.866.812-1.866 2.353-3.489 3.814-4.056 4.3-1.542 8.68.973 9.005 8.113M4.888 29.47c-.081-2.758.162-7.95.162-10.709 0-2.352-.162-4.543.162-6.896.568-3.245 2.19-4.624 4.544-4.949.973-.08 3.57-.08 4.624 0 1.541.081 1.866.081 3.57.081 2.92 0 8.924-.243 11.764.163M39.53 18.518c-.081 2.353-.162 6.328-.243 8.68 0 .569-.081 1.218-.081 1.785M42.126 29.714c.65 1.298 0 4.462-8.032 5.11-2.758.325-8.6.082-12.818.163-4.381.081-8.356-.243-12.737-.325-2.678-.08-8.6-1.135-6.653-5.11H5.05c2.11 0 3.813.08 5.841 0 3.245 0 7.464 0 10.466.08 3.083.082 4.706 0 7.87.082 3.164.08 6.571.162 9.735.08h2.11c.162-.08.73 0 1.054-.08z"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.334 29.565c-.065-.101.13.402.195.502.13.302.325.503.52.705.52.402 1.17.402 1.691.402 1.692-.1 4.163-.1 5.139-.302 1.04-.2 1.366-1.158 1.366-1.158M20.384 17.3c.08.082.567.325.73.407.486.162 1.217-.082 1.785-.406M32.959 1.237c-1.46.081-2.596.65-2.596 1.866 0 .487-.081 2.515-.081 3.002-.081 3.326-.163 3.813 0 7.383.08 1.217.973 1.866 1.46 1.866 2.758.324 7.95.162 11.44.162 1.297 0 1.054-3.164 1.054-4.949 1.541 1.217 3.732 2.515 5.192 3.408 0-2.029.163-9.574 0-10.628-1.46.81-3.57 1.703-5.192 2.677.081-3.083.081-4.868-2.596-4.868-1.136 0-5.517 0-8.681.081z"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
