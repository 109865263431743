import styled from '@emotion/styled';
import React from 'react';

const FooterContainer = styled.div`
    width: 100%;
    border-top: 1px solid #e5e5e5;
    background: #fff;
    min-height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 200;
`;

const Link = styled.a`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    text-decoration: underline;
    color: ${(props) => props.theme.font?.accentColor};
`;

const Divider = styled.div`
    width: 1px;
    height: 1rem;
    background: #797979;
    margin: 0 1rem;
`;

const termsUrl = 'https://www.asurion.com/terms-conditions/';
const privacyUrl = 'https://www.asurion.com/privacy-policy/';

export const AsurionFooter = () => (
    <FooterContainer>
        <Link href={termsUrl} rel="noreferrer" target="_blank">
            Terms of Use
        </Link>
        <Divider />
        <Link href={privacyUrl} rel="noreferrer" target="_blank">
            Privacy Policy
        </Link>
    </FooterContainer>
);
