import { Theme } from '@emotion/react';
import { SpeedPoint } from '../models';

export const getPercentChange = (data: SpeedPoint[]) => {
    // If there are at least two points in the data array, get the percent increase between the last two, otherwise,
    // set to undefined so we do not show the chip
    const currentSpeed = data[data.length - 1].speed;

    const percentChange = data.length > 1 ? (currentSpeed / data[data.length - 2].speed) * 100 - 100 : undefined;
    return percentChange;
};

export const getColorFromSpeeds = (data: SpeedPoint[], theme: Theme): string | undefined => {
    let percentChange = getPercentChange(data);

    const isDecrease = percentChange && percentChange < 0;

    if (percentChange && percentChange < 0) {
        percentChange *= -1;
    }

    let color: string | undefined = '#37E7A7';
    if (percentChange) {
        if (percentChange < 20) {
            color = theme?.components?.overviewSpeedChart?.neutralColor;
        } else if (isDecrease) {
            color = theme?.components?.overviewSpeedChart?.decreasedColor;
        } else {
            color = theme?.components?.overviewSpeedChart?.increasedColor;
        }
    } else {
        color = theme?.components?.overviewSpeedChart?.neutralColor;
    }

    return color;
};

export type PointMargin = {
    left: number;
    top: number;
    bottom: number;
};

export const getMarginFromChartPoint = (
    point: SpeedPoint,
    dataLength: number,
    index: number,
    chartMin: number,
    chartMax: number,
    chartWidth: number,
    chartHeight: number,
): PointMargin | undefined => {
    if (dataLength <= 4 || index !== 0) {
        let actualIndex = index;
        if (dataLength > 4) {
            actualIndex = index - 1;
        }

        const xPos = Math.min(3, actualIndex);
        const yPos = point.speed;

        // The chart points are always separated by a 1/4 of the chart's total width

        // The chart is actually the (width of the container + (width of container / 15))

        // There's an offset in the start of the points so the leftmost point is out
        // of view by (chartWidth / 10) so we adjust for that as well

        // This component is positioned by the top left corner, so adjust by half
        // the size of this component and the size of the chart's points 8px
        const marginLeft = xPos * ((chartWidth + chartWidth / 15) / 4) + chartWidth / 10 - 8;

        // For the y position of the point we get the position as a percentage of the
        // total height of chart by getting the distance from the min to the yPos,
        // then by dividing that distance by the height of the chart in speed units

        // Then we multiply that percentage by the pixel height of the chart (chartHeight)

        // There is a 30px margin on the bottom of the chart, so we add that offset
        // and also subtract half of the chart point
        const marginTop = ((yPos - chartMin) / (chartMax - chartMin)) * chartHeight + 30 - 0;

        // This is the offset to counteract the negative margin above, minus the
        // component offset since this starts below the component's height
        const marginBottom = ((yPos - chartMin) / (chartMax - chartMin)) * chartHeight + 0 - 2;

        const margin = { left: marginLeft, top: marginTop, bottom: marginBottom };
        return margin;
    }
    return undefined;
};

export const getMarginFromChartPoints = (
    data: SpeedPoint[],
    chartMin: number,
    chartMax: number,
    chartWidth: number,
    chartHeight: number,
): PointMargin[] => {
    const pointMargins: PointMargin[] = [];

    data.forEach((point, index) => {
        const margin = getMarginFromChartPoint(point, data.length, index, chartMin, chartMax, chartWidth, chartHeight);

        if (margin) {
            pointMargins.push(margin);
        }
    });

    return pointMargins;
};
