import React, { FunctionComponent } from 'react';
import { Theme, ThemeProvider } from '@emotion/react';

export const withThemeProviderWrapper = <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>,
    theme: Theme,
): FunctionComponent<P> => (props) => (
    <ThemeProvider theme={theme}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
    </ThemeProvider>
);
