import styled from '@emotion/styled';
import { BaseButton } from '@home-mgmt-shared/common-ui';
import React from 'react';

type TipsButtonProps = {
    link: string;
    analyticEventName: string;
};

const Button = styled(BaseButton)`
    background: #000000;
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    border: none;
    height: 46px;
    width: 100%;
    transition: transform 0.2s ease-in;
    user-select: none;

    button&:active {
        transform: scale(0.96);
    }
    button&:hover,
    button&:focus:active {
        outline: none;
    }
`;
const TipsLink = styled.a`
    width: 100%;
    outline: none;
`;

export const TipsButton = ({ link, analyticEventName }: TipsButtonProps) => (
    <TipsLink href={link}>
        <Button analyticEventName={analyticEventName}>Read our top wifi tips</Button>
    </TipsLink>
);
