import React from 'react';

export const Close = () => (
    <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="close icon"
    >
        <path
            d="M12.3327 1.13798L11.3893 0.195312L6.52799 5.05731L1.66599 0.195312L0.722656 1.13798L5.58466 5.99998L0.722656 10.862L1.66599 11.8046L6.52799 6.94265L11.3893 11.8046L12.3327 10.862L7.47066 5.99998L12.3327 1.13798Z"
            fill="black"
        />
    </svg>
);
